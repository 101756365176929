import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

// --- Credits component | toboContent: strapi query content from index | elementsImages: strapi query images from index ---
const Credits = (props: {
    toboContent: any,
    elementsImages: any,
    isMobile: boolean
}) => {

    // --- Getting tobo images from strapi image elements query    
    const _cardinalLogo: any = props?.elementsImages && props.elementsImages.filter((el: any) => (el?.localFile.name as string).includes('cardinal') || null)[0];
    const _locatiLogo: any = props?.elementsImages && props.elementsImages.filter((el: any) => (el?.localFile.name as string).includes('locati') || null)[0];
    const cardinalLogo: IGatsbyImageData = _cardinalLogo && getImage(_cardinalLogo?.localFile);
    const locatiLogo: IGatsbyImageData = _locatiLogo && getImage(_locatiLogo?.localFile);
    // ---

    // --- Getting credits content from strapi content query
    const credits: string = props?.toboContent && props.toboContent?.credits;
    // ---

    return (
        <div className='z-30 flex flex-col items-end justify-end w-full px-8 text-white h-1/2 bottom-2 lg:justify-center lg:items-center font-IBM lg:px-36'>
            <div className='text-lg uppercase lg:text-4xl'>
                Credits
            </div>
            <div className='flex flex-row-reverse items-center w-full lg:justify-center'>
                <div className='w-40 lg:w-80'>
                    <GatsbyImage image={cardinalLogo} alt={_cardinalLogo?.localFile.name}/>
                </div>
                <span className='mr-4 text-lg scale-x-150 lg:text-3xl lg:ml-4 lg:mr-12'>x</span>
                <div className='w-14 lg:w-32'>
                    <GatsbyImage image={locatiLogo} alt={_locatiLogo?.localFile.name}/>
                </div>
            </div>
            <div className='flex flex-col items-end w-2/3 lg:w-[45%] ml-20 text-xs text-end lg:text-center lg:items-center lg:text-xl lg:ml-0'>
                {/* --- Credits content text splitted on '-' to start a new line --- */}
                {   credits && 
                    credits.split('-').map((el: string, index: number) => {
                        return (
                            <span key={index} className='pb-1 lg:pb-2'>{el}</span>
                        )
                    })
                }
                {/* --- */}
            </div>
        </div>
    )
}

export default Credits;